*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: "Poppins";
}
.nowrap {
  white-space: nowrap;
}

.bg-light-blue {
  background: #003e8e;
}

.bg-dark-blue {
  background: #072244;
}

.bg-shade-blue {
  background: rgba(0, 62, 142, 0.1);
}

.text-dark-blue {
  color: #072244;
}

.text-light-blue {
  color: #003e8e;
}

nav#main-nav a {
  padding: 1.2em 1em;
  color: white !important;
}

nav#main-nav a:hover {
  background: #003e8e;
}

.nav-divider {
  height: 3px;
  background-color: #003e8e;
}

.bg-secondary {
  background: #f5f5f5 !important;
}

.text-secondary {
  background: #f5f5f5 !important;
}

@media (min-width: 768px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-opacity: 0;
  }

  100% {
    -webkit-opacity: 1;
  }

  0% {
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    -webkit-opacity: 0;
  }

  100% {
    -webkit-opacity: 1;
  }

  0% {
    -webkit-opacity: 0;
  }
}

.slideInFromRight {
  -webkit-animation-name: slideInFromRight;
  animation-name: slideInFromRight;
}

@keyframes slideInFromRight {
  0% {
    left: 100%;
  }

  100% {
    left: 0%;
  }

  0% {
    left: 100%;
  }
}

.slideInFromBottom {
  -webkit-animation-name: slideInFromBottom;
  animation-name: slideInFromBottom;
}

@keyframes slideInFromBottom {
  0% {
    top: 100%;
  }

  100% {
    top: 0%;
  }

  0% {
    top: 100%;
  }
}

.clickable {
  cursor: pointer;
}

.active-form-section {
  background: #dddddd !important;
  border-radius: 8px !important;
}

.label-required::after {
  content: " *";
  color: red;
}

.transition {
  transition: all ease-out 300ms;
}

.opacity-0 {
  opacity: 0;
}

.scale-0 {
  transform: scale(0);
}

.scale-90 {
  transform: scale(0.9);
}

.scale-100 {
  transform: scale(1);
}

[x-cloak] {
  display: none !important;
}

.clickable-card {
  cursor: pointer;
  transition: all 300ms;
  transform: scale(1);
}

.clickable-card:hover {
  transform: scale(1.1);
  position: relative;
  z-index: 1000;
  opacity: 0.8;
}

.accordion-enter {
  opacity: 0;
  transform: scale(0.9);
}

.accordion-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.accordion-exit {
  opacity: 1;
}

.accordion-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.active-check {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 0;
  border-radius: 4px;
  font-size: 15px;
  background-color: #3f8129;
  color: #fff;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: unset !important;
}

/* ======================== Lecture Timetable ================ */
.nav-tabs.timeTableNav .nav-link {
  height: 50px;
  font-size: 18px;
}
.nav-tabs.timeTableNav .nav-link.active {
  color: #ffffff;
  background-color: #072244;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs.timeTableNav li.nav-item.d-grid {
  width: 50%;
}
.course {
  padding: 7px;
  border-radius: 5px;
  margin: 20px 0;
}
.course h2 {
  font-size: 20px;
  margin-bottom: 0;
}

.QuestManag a {
  text-decoration: none;
  color: black;
}
.QuestManag h4 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.QuestManag .card {
  height: 100px;
}
.QuestManag .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
}
.QuestManag .card:hover {
  background-color: #072244;
  color: white;
}
.QuestManag .badge {
  font-size: 6em;
}
.midTermBtn > div {
  text-align: right;
}
.nav-tabs.timeTableNav .nav-link {
  background-color: #d3d3d329;
  color: black;
}
/* .objectiveCheck input[type="text"]{
    border-color: grey;
    border-radius: 5px ;
  } */
.objectiveCheck .form-label {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.objectiveCheck input[type="checkbox"] {
  height: 34px;
  width: 60px;
}

/* ================== Modal ====================== */

.modal {
  overflow-x: unset;
  overflow-y: unset;
}
.modal.custommodal.fade .modal-dialog {
  right: -320px;
  height: 100vh;
  -webkit-transition: opacity 0.8s linear, right 0.8s ease-out;
  -moz-transition: opacity 0.8s linear, right 0.8s ease-out;
  -o-transition: opacity 0.8s linear, right 0.8s ease-out;
  transition: opacity 0.8s linear, right 0.8s ease-out;
}
.modal.custommodal.show .modal-dialog {
  right: 0;
}
.custommodal .modal-dialog {
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.custommodal .modal-dialog-scrollable .modal-content {
  height: 100vh;
  overflow-y: scroll;
}
.nav-tabs.timeTableNav .nav-link {
  height: 50px;
  font-size: 18px;
}

.nav-tabs.timeTableNav .nav-link.active {
  color: #ffffff;
  background-color: #072244;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs.timeTableNav.timeTableNavBtm .nav-link {
  background-color: #d3d3d329 !important;
}

.nav-tabs.timeTableNav.timeTableNavBtm .nav-link.active {
  color: #072244 !important;
  background-color: #d4d6da !important;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs.timeTableNav li.nav-item.d-grid {
  width: 50%;
}

.course {
  padding: 7px;
  border-radius: 5px;
  margin: 20px 0;
}

.course h2 {
  font-size: 20px;
  margin-bottom: 0;
}

table tbody {
  position: relative !important;
}

table tbody tr:active td {
  width: inherit !important;
}
table tbody tr td {
  width: inherit !important;
}

#list-example {
  width: 30%;
}

.scrollspy-example {
  width: 100%;
}
button.button.remove svg {
  width: 15px;
  height: 25px;
  margin: 0 0 -4px 0;
}
button.button.remove {
  position: absolute;
  right: -2px;
  top: 0px;
  padding: 11px 16px;
}

.program-modal-box ul {
  width: 25%;
  padding: 0;
  list-style-type: none;
  background-color: #f5f5f5 !important;
}

.program-modal-box ul li a {
  text-decoration: none;
  font-size: 17px;
  color: #212529;
  padding: 18px 14px;
  display: block;
}

.program-modal {
  display: flex;
  justify-content: flex-start;
}

.program-modal input,
.program-modal select,
.program-modal textarea {
  border: 2px solid #ced4da;
  padding: 12px 10px;
}
.program-modal-box .tab-content {
  width: 73%;
  margin-left: auto;
}
.program-modal-box .nav-item {
  width: 100%;
  cursor: pointer;
}
.program-modal-box .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: #dddddd !important;
}
.program-modal-box .nav {
  display: block;
}
.arror-right a {
  background-color: #ddd;
  color: #212529;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.arror-right svg {
  margin: 0 !important;
}
.studylevel-img {
  width: 300px;
}

.calendar-remove {
  right: -62px !important;
  top: 2px !important;
  padding: 3px 10px !important;
}
.d-table-caption {
  display: table-caption;
}

.table-row {
  vertical-align: middle;
}
/* table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
} */
.table-transcript {
  border: 0.1px solid rgb(0, 0, 0);
  border-collapse: collapse;
}
.timetable_hover:hover {
  background-color: #dee2e6;
}
.timetable-box {
  width: 30%;
  display: inline-table;
  background-color: #fff;
  margin: 0 30px 30px 0;
  padding: 20px 15px;
  box-shadow: 5px 5px 20px 5px rgb(49 72 91 / 10%);
  border-radius: 15px;
  cursor: pointer;
}
.timetable-box span {
  width: 120px;
}
.enrollment-tabs li {
  width: 50%;
  margin: 30px 0 0px 0;
  cursor: pointer;
}

.enrollment-tabs li a {
  padding: 10px 10px;
}

.enrollment-tabs .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: #003e8e;
  color: #fff;
}
.d-table-caption {
  display: table-caption;
}

.formikForm .formikCheck {
  padding: 0;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  margin: 0;
  margin-right: 5px;
}
.transcript-paragraph {
  position: relative;
  right: 5.5%;
}

.transcript-li {
  position: relative;
  left: 3%;
}

.gazete-date {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.gazette-list {
  position: relative;
  left: 8%;
}

.table-gazette {
  border: 0.1px solid #c9c9c9;
  border-collapse: collapse;
}

@media print {
  body * {
    visibility: hidden;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  #section-to-print {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    font-size: 14px;
    padding: 10px !important;
  }

  #no-print {
    display: none;
  }
}

.without_ampm::-webkit-datetime-edit-ampm-field {
  display: none;
}
input[type="time"]::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  margin: -10px;
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #003e8e !important;
}
