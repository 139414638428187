.modal {
    overflow-x: unset;
    overflow-y: unset;
  }
  .modal.custommodal.fade .modal-dialog {
    right: -320px;
    height: 100vh;
    -webkit-transition: opacity 0.8s linear, right 0.8s ease-out;
    -moz-transition: opacity 0.8s linear, right 0.8s ease-out;
    -o-transition: opacity 0.8s linear, right 0.8s ease-out;
    transition: opacity 0.8s linear, right 0.8s ease-out;
  }
  
  .modal.custommodal.show .modal-dialog {
    right: 0;
  }
  
  .custommodal .modal-dialog {
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  .custommodal .modal-dialog-scrollable .modal-content {
    height: 100vh;
    overflow-y: scroll;
  }
  .btns-delete-modal {
    display: flex;
    justify-content: center;
  }
  .delete-modal .modal-body {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
